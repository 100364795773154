import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/img/ai-chat5.png';

import { Layout, Menu, Dropdown, Button, Avatar, Tabs, List, Typography, Form, Input, Upload, message, Table, Modal, Spin, Card} from 'antd';
import { UserOutlined, LogoutOutlined, FileOutlined, SitemapOutlined, MessageOutlined, DeploymentUnitOutlined, UploadOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-github';
import "ace-builds/webpack-resolver";

import moment from 'moment';
import { FunnelChart, Funnel, Tooltip, LabelList, LineChart, Line, XAxis, YAxis, Legend, CartesianGrid, AreaChart, ResponsiveContainer, Area } from 'recharts';

import SettingsForm from '../components/SettingsForm';
import InputCollectionWizard from '../components/InputCollectionWizard';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
const { TabPane } = Tabs;
const { Paragraph, Link, Text, Title } = Typography;

const AdminHomePage = () => {
  
  const api_base_url = 'https://api.outscore.ai';

  const [activeMenu, setActiveMenu] = useState('Home');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [sitemapUrl, setSitemapUrl] = useState('');
  let username = localStorage.getItem('username');
  let urlslug = localStorage.getItem('urlslug');
  const [sitemaps, setSitemaps] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileList, setSelectedFileList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [filenames, setFilenames] = useState([]);

  const navigate = useNavigate();  

  const [activeDeployTab, setActiveDeployTab] = useState('newwebsite');
  const [activeSourcesTab, setActiveSourcesTab] = useState('sitemaps');

  const conversions = ['Leads', 'Site Registrations', 'Newsletter Signup','Event Registrations', 'Purchases'];
  const [activeConversionTab, setActiveConversionTab] = useState(0);

  const columns = [
    {
      title: 'Lead Name',
      dataIndex: 'lead_name',
      key: 'lead_name',
    },
    {
      title: 'Email',
      dataIndex: 'lead_email',
      key: 'lead_email',
    },
    {
      title: 'Phone',
      dataIndex: 'lead_phone',
      key: 'lead_phone',
    },
    {
      title: 'Address',
      dataIndex: 'lead_address',
      key: 'lead_address',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Updated At',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (text) => new Date(text).toLocaleString(),
    },
  ];

  const [contentPages, setContentPages] = useState([]);
  const [selectedContentPage, setSelectedContentPage] = useState(null);
  const [isContentModalVisible, setIsContentModalVisible] = useState(false);
  const [isContentPageEditing, setIsContentPageEditing] = useState(false);
  const [contentPageHtml, setContentPageHtml] = useState('');

  useEffect(() => {
    fetchContentPages();
  }, []);

  const fetchContentPages = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${api_base_url}/admin/webpage`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          username // Send username as a query parameter
        }
      });
      console.log('HTML pages:', response.data);
      setContentPages(response.data);
    } catch (error) {
      console.error('Error fetching HTML pages:', error);
      message.error('Failed to fetch HTML pages');
    }
  };

  const handleContentPageView = (page) => {
    setSelectedContentPage(page);
    setContentPageHtml(page.content);
    setIsContentModalVisible(true);
    setIsContentPageEditing(false);
  };

  const handleContentPageEdit = () => {
    setIsContentPageEditing(true);
  };

  const handleContentPageSave = async () => {
    try {
      await axios.put(`${api_base_url}/admin/webpage/${selectedContentPage.id}`, { content: contentPageHtml, username: username },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }
      );
      message.success('HTML page updated successfully');
      setIsContentModalVisible(false);
      fetchContentPages();
    } catch (error) {
      console.error('Error updating HTML page:', error);
      message.error('Failed to update HTML page');
    }
  };

  const contentPageColumns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Button type="link" onClick={() => handleContentPageView(record)}>View</Button>
      ),
    },
  ];

  const fetchSitemaps = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${api_base_url}/admin/sitemap`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          username // Send username as a query parameter
        }
      });
      setSitemaps(response.data.sitemaps); // Assuming the API returns an object with a sitemaps array
    } catch (error) {
      console.error('Failed to fetch sitemaps:', error);
    }
  };

  useEffect(() => {
  
    if (activeMenu === 'Input Sources') {
      fetchSitemaps();
    }
  }, [activeMenu]);

  useEffect(() => {
    const fetchFiles = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${api_base_url}/admin/file`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: {
            username // Send username as a query parameter
          }
        });
        setFilenames(response.data.file_names); // Assuming the API returns an object with a files array
      } catch (error) {
        console.error('Failed to fetch files:', error);
      }
    };
    if (activeMenu === 'Input Sources') {
      fetchFiles();
    }
  }, [activeMenu]); // Dependency array to refetch when username changes
  
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('urlslug');
    navigate('/login');
  };  

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  const [chatThreads, setChatThreads] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [conversionData, setConversionData] = useState( [[]] );

  const fetchChatThreads = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${api_base_url}/admin/chatthread`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }, 
        params: {
          username: username
        }
      });
      console.log('Chat threads:', response);
      const sortedThreads = response.data.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      setChatThreads(sortedThreads);
    } catch (error) {
      console.error(error);
    }
  };

  // Define the function to handle conversation click
const handleConversationClick = async (chatThreadId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${api_base_url}/admin/chatmessage`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          username: username,
          thread_id: chatThreadId
        }
      });
      console.log('Chat messages:', response);
      setChatMessages(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleConversionTabChange = (key) => {
    console.log(key);
    setActiveConversionTab(key);
  };
  
  const listConversionData = async (conversionType) => {
    fetchConversionData();
  }
  
    // Fetch chat threads when the component mounts
    useEffect(() => {
      fetchChatThreads();
  
      fetchConversionData();
    }, []);

    const fetchConversionData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${api_base_url}/admin/lead`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: {
            username // Send username as a query parameter
          }
        });
        console.log(response.data);
        setConversionData([response.data]);
      } catch (error) {
        console.error(error); // Handle error
      }
       
    };

    //for loading visitor stats
    const [visitorData, setVisitorData] = useState({
      visitor_timestamps: [],
      thread_timestamps: [],
      lead_timestamps: []
    });
    const [visitorsData, setVisitorsData] = useState([{}]);
    const [threadData, setThreadData] = useState([{}]);
    const [leadData, setLeadData] = useState([{}]);

    const [visitorStats, setVisitorStats] = useState([
      {"name": "visitors", "value": 0},
      {"name": "chatLogs", "value": 0},
      {"name": "leads", "value": 0}]
    );
    useEffect(() => {
      fetchVisitorStats();
    }, []);

    const fetchVisitorStats = async () => {
      let token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${api_base_url}/admin/visitorstat`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: {
            username // Send username as a query parameter
          }
        });
        console.log('Visitor stats:', response.data);
        const { visitor_timestamps, thread_timestamps, lead_timestamps } = response.data;
        //setVisitorData(response.data);

       
        const getCurrentWeekStats = (visitorTimestamps, threadTimestamps, leadTimestamps) => {
          const startOfWeek = moment().startOf('isoWeek'); // Monday morning
          const endOfWeek = moment().endOf('isoWeek'); // Sunday night
        
          const countOccurrences = (timestamps) => {
            return timestamps.filter(timestamp => {
              const date = moment(timestamp);
              return date.isBetween(startOfWeek, endOfWeek, null, '[]');
            }).length;
          };
          
          return [
            { name: 'Visitors', value: countOccurrences(visitorTimestamps) },
            { name: 'Chat Logs', value: countOccurrences(threadTimestamps) },
            { name: 'Leads', value: countOccurrences(leadTimestamps) }
          ];
        };        

        // Process the timestamps to get counts for the current week
        const currentWeekStats = getCurrentWeekStats(visitor_timestamps, thread_timestamps, lead_timestamps);
        console.log('Current week stats:', currentWeekStats);
        
        // Update state with the current week stats
        setVisitorStats(currentWeekStats);

        const processData = (timestamps) => {
          const weekCounts = timestamps.reduce((acc, timestamp) => {
            const week = moment(timestamp).startOf('week').format('YYYY-MM-DD');
            if (!acc[week]) {
              acc[week] = 0;
            }
            acc[week]++;
            return acc;
          }, {});
      
          return Object.keys(weekCounts).map(week => ({
            week,
            count: weekCounts[week]
          }));
        };
        //console.log(processData(visitor_timestamps));
        setVisitorsData(processData(visitor_timestamps));
        setThreadData(processData(thread_timestamps));
        setLeadData(processData(lead_timestamps));


      } catch (error) {
        console.error('Error fetching visitor stats:', error);
        message.error('Failed to fetch visitor stats');
      }
    }

    const FunnelChartComponent = () => {
      return (
        <FunnelChart width={400} height={400}>
          <Tooltip />
          <Funnel
            dataKey="value"
            data={visitorStats}
            isAnimationActive
          >
            <LabelList position="right" fill="#000" stroke="none" dataKey="name" />
          </Funnel>
        </FunnelChart>
      );
    };

/*   const renderRightPanel = () => {
    switch (activeMenu) {
      case 'Home':
        return <div style={stylesheet.boxMsg}>
                <h1 style={stylesheet.boxMsgH1}>Welcome to Outscore Admin</h1>
                <p style={stylesheet.boxMsgP}><i>Outscore helps convert Visitors to Leads</i></p>
              </div>;
      case 'Input Sources':
        return (
          <div>
            <div style={stylesheet.boxMsg}>
                <h1 style={stylesheet.boxMsgH1}>Train your Salesbot</h1>
                <p style={stylesheet.boxMsgP}><i>Please upload your website Sitemap and any other PDFs to train the Outscore Salesbot.</i></p>
            </div>
            <div  style={stylesheet.tabContainer}>
              <button style={activeSourcesTab === 'sitemaps' ? stylesheet.activeTab : stylesheet.tab} onClick={() => handleSourcesTabChange('sitemaps')}>Sitemaps</button>
              <button style={activeSourcesTab === 'files' ? stylesheet.activeTab : stylesheet.tab} onClick={() => handleSourcesTabChange('files')}>File Uploads</button>
            </div>
            <div  style={stylesheet.contentContainer}>
              {activeSourcesTab === 'sitemaps' && <SitemapsContent />}
              {activeSourcesTab === 'files' && <FilesContent />}
            </div>
          </div>
        );
        
        case 'Deploy':
          return (
            <div>
              <div style={stylesheet.boxMsg}>
                <h1 style={stylesheet.boxMsgH1}>Deploy anywhere</h1>
                <p style={stylesheet.boxMsgP}><i>The Salesbot can guide your visitors on your website or as a fullscreen standalone bot.</i></p>
              </div>
              <div  style={stylesheet.tabContainer}>
                <button style={activeDeployTab === 'fullscreen' ? stylesheet.activeTab : stylesheet.tab} onClick={() => handleDeployTabChange('fullscreen')}>Fullscreen</button>
                <button style={activeDeployTab === 'popup' ? stylesheet.activeTab : stylesheet.tab} onClick={() => handleDeployTabChange('popup')}>Website - Non Wordpress</button>
                <button style={activeDeployTab === 'wordpress' ? stylesheet.activeTab : stylesheet.tab} onClick={() => handleDeployTabChange('wordpress')}>Website - Wordpress</button>
              </div>
              <div  style={stylesheet.contentContainer}>
                {activeDeployTab === 'fullscreen' && <FullscreenContent />}
                {activeDeployTab === 'popup' && <PopupContent />}
                {activeDeployTab === 'wordpress' && <WordPressContent />}
              </div>
            </div>
          );           
      case 'User Chat Logs':
        //fetchChatThreads();
        
        // Map chat threads to ConversationList format
        const conversations = chatThreads.map((thread) => ({
          id: thread.id,
          date: new Date(thread.created_at).toLocaleString('en-GB', { 
            day: 'numeric', 
            month: 'long', 
            year: 'numeric', 
            hour: '2-digit', 
            minute: '2-digit' 
          }), // Format the date
        }));

        return (
          <div style={stylesheet.chatContainer}>
            <div style={stylesheet.conversationList}>
              
              <ConversationList>
                <h2>Visitor Chats</h2>
                {conversations.map((conversation) => (
                  <Conversation
                    key={conversation.id}
                    name={conversation.date}
                    onClick={() => handleConversationClick(conversation.id)}
                  />
                ))}
              </ConversationList>
            </div>
            <div style={stylesheet.messageContainer}>
              <MessageList style={stylesheet.messageList}>
                {chatMessages.map((chatMessage, index) => (
                  <React.Fragment key={index}>
                    {chatMessage.user_message && (
                      <Message
                        model={{
                          message: chatMessage.user_message,
                          sentTime: new Date(chatMessage.created_at).toLocaleTimeString(),
                          sender: "user",
                          direction: "outgoing",
                        }}
                      />
                    )}
                    {chatMessage.ai_message && (
                      <Message
                        model={{
                          message: chatMessage.ai_message,
                          sentTime: new Date(chatMessage.created_at).toLocaleTimeString(),
                          sender: "AI",
                          direction: "incoming",
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </MessageList>
            </div>
          </div>
        );
      default:
        return <div>Select an option from the left panel</div>;
    }
  }; */

  const cardStyle = {
    borderRadius: '10px',
    backgroundColor: '#f0f2f5',
    padding: '10px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '200px',
    height: '180px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  };

  const renderRightPanel = () => {
    switch (activeMenu) {
      case 'Home':
        return (
          <div style={{ padding: '10px', textAlign: 'center' }}>
            <Typography.Title level={1}>Welcome to Outscore Admin</Typography.Title>
            <Typography.Paragraph italic>Outscore helps convert Visitors to Leads</Typography.Paragraph>

            <div style={{ padding: '10px' }}>
            <Typography.Title level={2}>Visitor Stats</Typography.Title>
            <p>This week</p>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
              <div>
                <Card style={cardStyle}>
                <Typography.Title level={4}>Visitors</Typography.Title>
                <Typography.Title level={2}>{visitorStats[0].value}</Typography.Title>
                </Card>
              </div>
              <div>
                <Card style={cardStyle}>
                <Typography.Title level={4}>Chats</Typography.Title>
                <Typography.Title level={2}>{visitorStats[1].value}</Typography.Title>
                <a href="#"onClick={() => handleMenuClick('Visitor Chat Logs')}>View Chat Logs</a>
                </Card>
              </div>
              <div>
                <Card style={cardStyle}>
                <Typography.Title level={4}>Conversions</Typography.Title>
                <Typography.Title level={2}>{visitorStats[2].value}</Typography.Title>
                <a href="#"onClick={() => handleMenuClick('Conversions')}>View Conversions</a>
                </Card>
              </div>          
            </div>
            {/* <LineChart width={600} height={300} data={leadData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="week" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="count" stroke="#ffc658" name="Leads" />
            </LineChart> */}

            <Typography.Title level={3}>Leads</Typography.Title>
            <ResponsiveContainer width="100%" height={400}>
            <AreaChart width={730} height={250} data={leadData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="week" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="count" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" name="Leads"/>
            </AreaChart>
            </ResponsiveContainer>


          </div>
          </div>
        );
      case 'Input Sources':
        return (
          <div>
            <div style={{ padding: '24px', textAlign: 'center' }}>
              <Typography.Title level={1}>Train your Salesbot</Typography.Title>
              <Typography.Paragraph italic>Please upload your website Sitemap and any other PDFs to train the Outscore Salesbot.</Typography.Paragraph>
            </div>
            <Tabs activeKey={activeSourcesTab} onChange={handleSourcesTabChange}>
              <TabPane tab="Sitemaps" key="sitemaps">
                <SitemapsContent />
              </TabPane>
              <TabPane tab="File Uploads" key="files">
                <FilesContent />
              </TabPane>
            </Tabs>
          </div>
        );
      case 'Manage Content':
        return (
          <div>
            <Table dataSource={contentPages} columns={contentPageColumns} rowKey="id" />
            <Modal
              title={selectedContentPage ? selectedContentPage.description : ''}
              visible={isContentModalVisible}
              onCancel={() => setIsContentModalVisible(false)}
              footer={[
                <Button key="cancel" onClick={() => setIsContentModalVisible(false)}>Cancel</Button>,
                isContentPageEditing ? (
                  <Button key="save" type="primary" onClick={handleContentPageSave}>Save</Button>
                ) : (
                  <Button key="edit" type="primary" onClick={handleContentPageEdit}>Edit</Button>
                ),
              ]}
              width="100%"
            >
              {isContentPageEditing ? (
                <AceEditor
                  mode="html"
                  theme="github"
                  value={contentPageHtml}
                  onChange={setContentPageHtml}
                  name="html-editor"
                  editorProps={{ $blockScrolling: true }}
                  width="100%"
                  height="300px"
                  wrapEnabled={true}
                />
              ) : (
                <div>
                  <h3>Text View:</h3>
                  <Input.TextArea value={contentPageHtml} readOnly rows={10} />
                  <h3>HTML Code View:</h3>
                  <AceEditor
                    mode="html"
                    theme="github"
                    value={contentPageHtml}
                    readOnly
                    name="html-viewer"
                    editorProps={{ $blockScrolling: true }}
                    width="100%"
                    height="300px"
                    wrapEnabled={true}
                  />
                </div>
              )}
            </Modal>
          </div>
        );
      case 'Deploy':
        return (
          <div>
            <div style={{ padding: '24px', textAlign: 'center' }}>
              <Typography.Title level={1}>Deploy anywhere</Typography.Title>
              <Typography.Paragraph italic>The Salesbot can guide your visitors on your existing website or alongside Generated content.</Typography.Paragraph>
            </div>
            <Tabs activeKey={activeDeployTab} onChange={handleDeployTabChange}>
              <TabPane tab="Generated content" key="newwebsite">
                <NewwebsiteContent />
              </TabPane>
              <TabPane tab="Existing Website (non-Wordpress)" key="popup">
                <PopupContent />
              </TabPane>
              <TabPane tab="Existing Website (Wordpress)" key="wordpress">
                <WordPressContent />
              </TabPane>
            </Tabs>
          </div>
        );
      case 'Settings':
        return (
          <div>
            <div style={{ padding: '24px', textAlign: 'center' }}>
              <Typography.Title level={1}>Settings</Typography.Title>
              <Typography.Paragraph italic>Configure your Salesbot settings below.</Typography.Paragraph>
            </div>
            <SettingsForm />
          </div>
        );
      case 'Visitor Stats':

        /* const processData = (timestamps) => {
          return timestamps.map(timestamp => ({
            time: moment(timestamp).format('YYYY-MM-DD HH:mm:ss'),
            count: 1
          }));
        };
      
        const visitorsData = processData(visitorData.visitor_timestamps);
        const threadData = processData(visitorData.thread_timestamps);
        const leadData = processData(visitorData.lead_timestamps); */

        return (
          <div style={{ padding: '24px' }}>
            <Typography.Title level={2}>Visitor Stats</Typography.Title>
            <p>This week</p>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography.Title level={4}>Total Visitors</Typography.Title>
                <Typography.Title level={2}>{visitorStats[0].value}</Typography.Title>
              </div>
              <div>
                <Typography.Title level={4}>Total Chats</Typography.Title>
                <Typography.Title level={2}>{visitorStats[1].value}</Typography.Title>
                <a href="#"onClick={() => handleMenuClick('Visitor Chat Logs')}>View Chat Logs</a>
              </div>
              <div>
                <Typography.Title level={4}>Total Conversions</Typography.Title>
                <Typography.Title level={2}>{visitorStats[2].value}</Typography.Title>
                <a href="#"onClick={() => handleMenuClick('Conversions')}>View Conversions</a>
              </div>          
            </div>
            {/* <p>Funnel view</p>
            <FunnelChartComponent /> */}
            <p>Past weeks</p>
            {/* <Layout>
              <Content>
                <LineChart width={600} height={300} data={visitorsData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="week" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="count" stroke="#8884d8" name="Visitors" />
                </LineChart>
                <LineChart width={600} height={300} data={threadData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="week" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="count" stroke="#82ca9d" name="Threads" />
                </LineChart>
                <LineChart width={600} height={300} data={leadData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="week" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="count" stroke="#ffc658" name="Leads" />
                </LineChart>
              </Content>
            </Layout> */}
            <Typography.Title level={3}>Visitors</Typography.Title>
            <ResponsiveContainer width="100%" height={400}>
            <AreaChart width={730} height={250} data={visitorsData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="week" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="count" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" name="Visitors"/>
            </AreaChart>
            </ResponsiveContainer>

            <Typography.Title level={3}>Chat Threads</Typography.Title>
            <ResponsiveContainer width="100%" height={400}>
            <AreaChart width={730} height={250} data={threadData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="week" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="count" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" name="Chat Threads"/>
            </AreaChart>            
            </ResponsiveContainer>            

            <Typography.Title level={3}>Leads</Typography.Title>
            <ResponsiveContainer width="100%" height={400}>
            <AreaChart width={730} height={250} data={leadData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="week" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="count" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" name="Threads"/>
            </AreaChart>            
            </ResponsiveContainer>            

          </div>
        );
      case 'Visitor Chat Logs':
        const conversations = chatThreads.map((thread) => ({
          id: thread.id,
          date: new Date(thread.created_at).toLocaleString('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          }),
        }));
  
        return (
          <div style={{ display: 'flex', padding: '24px' }}>
            <div style={{ flex: 1, marginRight: '24px' }}>
              <Typography.Title level={2}>Visitor Chats</Typography.Title>
              <List
                itemLayout="horizontal"
                dataSource={conversations}
                renderItem={conversation => (
                  <List.Item onClick={() => handleConversationClick(conversation.id)}>
                    <List.Item.Meta
                      avatar={<MessageOutlined />}
                      title={conversation.date}
                    />
                  </List.Item>
                )}
              />
            </div>
            <div style={{ flex: 2 }}>
              <List
                itemLayout="vertical"
                dataSource={chatMessages}
                renderItem={(chatMessage, index) => (
                  <List.Item key={index}>
                    {chatMessage.user_message && (
                      <List.Item.Meta
                        title="User"
                        description={<div
                          dangerouslySetInnerHTML={{ __html: chatMessage.user_message }}
                        />}
                      />
                    )}
                    {chatMessage.ai_message && (
                      <List.Item.Meta
                        title="AI"
                        description={<div
                          dangerouslySetInnerHTML={{ __html: chatMessage.ai_message }}
                        />}
                      />
                    )}
                  </List.Item>
                )}
              />
            </div>
          </div>
        );
        case 'Conversions':

          return (
            <div style={{ padding: '24px' }}>
              <Typography.Title level={2} style={{ width: '100%' }}>Conversion Types</Typography.Title>
              <Tabs defaultActiveKey={0} onChange={handleConversionTabChange}>
                {conversions.map((conversion, index) => (
                  <TabPane
                    tab={
                      <span>
                        <MessageOutlined />
                        {conversion}
                      </span>
                    }
                    key={index}
                  />
                ))}
              </Tabs>
              <div style={{ marginTop: '24px' }}>
                <Table dataSource={conversionData[activeConversionTab]} columns={columns} rowKey={(record) => record.id} />
              </div>
            </div>
          );        
      case 'InputCollection':
        return <InputCollectionWizard />;
      default:
        return <div>Select an option from the left panel</div>;
    }
  };

  const handleSourcesTabChange = (tab) => {
    setActiveSourcesTab(tab);
  };

  const SitemapsContent = () => (
    <div>
      <Typography.Paragraph strong>Existing Sitemaps:</Typography.Paragraph>
      {(sitemaps?.length ?? 0) > 0 ? (
        <List
          bordered
          dataSource={sitemaps}
          renderItem={(sitemap) => (
            <List.Item>{sitemap}</List.Item>
          )}
        />
      ) : (
        <Typography.Paragraph>No sitemaps uploaded.</Typography.Paragraph>
      )}
      {sitemapUrlList.length > 0 ? (
      <div><Typography.Paragraph strong>Processing pages from new Sitemap: {sitemapUrl}</Typography.Paragraph>
      <List
        bordered
        dataSource={sitemapUrlList}
        renderItem={(item) => (
          <List.Item
            actions={[
              item.processed ? (
                <CheckOutlined style={{ color: 'green' }} />
              ) : (
                <CloseOutlined style={{ color: 'red' }} />
              )
            ]}
          >
            {item.url}
          </List.Item>
        )}
      />
      </div>) : (<div></div>)}
      
      {sitemapMsg !== '' ? (
      <Typography.Paragraph type="danger">{sitemapMsg}</Typography.Paragraph>) : (<div></div>)}
      {sitemapWaiting ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
        <Spin size="large" />
        </div>
      ) : (<div></div>)}

      <Form onFinish={handleSitemapSubmit} layout="vertical">
        <Form.Item label="Add Sitemap URL:" name="sitemapUrl">
          <Input
            placeholder="Enter Sitemap URL"
            value={sitemapUrl}
            onChange={(e) => setSitemapUrl(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Submit</Button>
        </Form.Item>
      </Form>
    </div>
  );

  const FilesContent = () => (
    <div>
      <Typography.Paragraph strong>Existing Files:</Typography.Paragraph>
      {(filenames?.length ?? 0) > 0 ? (
        <List
          bordered
          dataSource={filenames}
          renderItem={(filename) => (
            <List.Item>{filename}</List.Item>
          )}
        />
      ) : (
        <Typography.Paragraph>No files uploaded.</Typography.Paragraph>
      )}
      <Form onFinish={handleFileSubmit} layout="vertical">
        <Form.Item label="Upload File:" name="file">
          <Upload beforeUpload={() => false} onChange={handleFileChange} fileList={selectedFileList}>
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Submit</Button>
        </Form.Item>
        {errorMessage && <Typography.Paragraph type="danger">{errorMessage}</Typography.Paragraph>}
      </Form>
    </div>
  );

  const handleDeployTabChange = (tab) => {
    setActiveDeployTab(tab);
  };

  const NewwebsiteContent = () => {
    const [urlSlug, setUrlSlug] = useState(localStorage.getItem('urlslug'));
    const [responseMessage, setResponseMessage] = useState('');
    const [isSlugEditing, setIsSlugEditing] = useState(false);
    const handleUrlSlugChange = (e) => {
      setUrlSlug(e.target.value);
    };

    const handleUrlSlugSubmit = async () => {
      const token = localStorage.getItem('token');
      const headers = {
        'Authorization': `Bearer ${token}`,
      };
  
      try {
        const response = await axios.post(`${api_base_url}/admin/urlslug`, { url_slug: urlSlug, username: username }, { headers });
        if (response.status === 200) {
          setResponseMessage('URL slug updated successfully');
          message.success('URL slug updated successfully');
          setIsSlugEditing(false);
          //Update the local storage and the variable with new value
          urlslug = urlSlug; 
          localStorage.setItem('urlslug', urlSlug);
        } else {
          setResponseMessage('Failed to update URL slug');
          message.error('Failed to update URL slug');
        }
      } catch (error) {
        console.error('Error updating URL slug:', error);
        setResponseMessage('Error updating URL slug');
        message.error('Error updating URL slug');
      }
    };

    const toggleEditMode = () => {
      setIsSlugEditing(!isSlugEditing);
    };

    return (
    <div>
      <Paragraph strong>Chatbot with Generated content:</Paragraph>
      <Paragraph>
        <Link href={`https://chat.outscore.ai/${urlslug}`} target='_blank'>Click here</Link>
      </Paragraph>
      {isSlugEditing ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Paragraph strong>Current URL Slug: </Paragraph>
          <Paragraph>
            <Text>https://chat.outscore.ai/</Text>
            <Input
              placeholder="Enter new URL slug"
              value={urlSlug}
              onChange={handleUrlSlugChange}
              style={{ marginLeft: '10px', marginBottom: '10px' }}
            />
            <Button type="primary" onClick={handleUrlSlugSubmit}>Update URL Slug</Button>
            <Button onClick={toggleEditMode} style={{ marginLeft: '10px' }}>Cancel</Button>
          </Paragraph>
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Paragraph strong>Current URL Slug: </Paragraph>
          <Paragraph>
            <Text>https://chat.outscore.ai/{urlSlug}</Text>
            <Button type="link" onClick={toggleEditMode} style={{ marginLeft: '10px' }}>Edit</Button>
          </Paragraph>
        </div>
      )}
      {responseMessage && <Paragraph type="danger">{responseMessage}</Paragraph>}
    </div>
    );
  };
  
  const PopupContent = () => (
    <div>
      <Paragraph strong>For Website popup chatbots:</Paragraph>
      <Paragraph>
        Place the Script tag in the HTML page of your website, just above the closing body tag. <br />
        For the bot to come on all pages, place in a section that comes on all pages like a Footer section.
      </Paragraph>
      <Paragraph>Replace YOUR_CLIENT_ID_HERE with your client id</Paragraph>
      <div style={{ position: 'relative', background: '#f5f5f5', padding: '20px', borderRadius: '4px', overflow: 'auto' }}>
        <Button
          style={{ position: 'absolute', top: '10px', right: '10px' }}
          onClick={(e) => {
            navigator.clipboard.writeText(`
  <script>
      window.myScriptData = {
        clientId: 'YOUR_CLIENT_ID_HERE'
      };
  
      (function(w,d,s,f,js,fjs){
        js=d.createElement(s);
        fjs=d.getElementsByTagName(s)[0];
        js.async=1;
        js.src=f;
        js.id='myWidgetScript';
        fjs.parentNode.insertBefore(js,fjs);
      }(window,document,'script','https://api.outscore.ai/admin/outscorejs'));
  </script>`);
            message.success('Copied to clipboard!');
          }}
        >
          Copy
        </Button>
        <pre>
          <code>
            {`
  <script>
      window.myScriptData = {
        clientId: 'YOUR_CLIENT_ID_HERE'
      };
  
      (function(w,d,s,f,js,fjs){
        js=d.createElement(s);
        fjs=d.getElementsByTagName(s)[0];
        js.async=1;
        js.src=f;
        js.id='myWidgetScript';
        fjs.parentNode.insertBefore(js,fjs);
      }(window,document,'script','https://api.outscore.ai/admin/outscorejs'));
  </script>`}
          </code>
        </pre>
      </div>
    </div>
  );
  
  const WordPressContent = () => (
    <div>
      <Paragraph strong>For WordPress:</Paragraph>
      <Paragraph>
        1. Login to WordPress Admin.<br />
        2. On the Left Panel, go to Appearance -> Theme File Editor.<br />
        3. Edit the Theme Functions file (functions.php)<br />
        4. Add the following code to the end of this file. <br />
        <Text>Replace YOUR_CLIENT_ID_HERE with your client id</Text><br />
        5. Update File.<br />
        6. Delete Cache on WordPress using the Top Menu option.<br />
        7. Refresh your WordPress site and you should see the chatbot on the bottom right corner as chat icon.
      </Paragraph>
      <div style={{ position: 'relative', background: '#f5f5f5', padding: '20px', borderRadius: '4px', overflow: 'auto' }}>
        <Button
          style={{ position: 'absolute', top: '10px', right: '10px' }}
          onClick={(e) => {
            navigator.clipboard.writeText(`
  function outscore_scripts() {
    // Register the script
    wp_register_script('outscore-js', 'https://api.outscore.ai/admin/outscorejs', array('jquery'), null, true);
    $client_id = 'YOUR_CLIENT_ID_HERE';
    wp_localize_script(
        'outscore-js', // Handle
        'myScriptData', // Object name
        array(
            'clientId' => $client_id, // Data to pass to the script
        )
    );
  
    // Enqueue the script
    wp_enqueue_script('outscore-js');
  }
  add_action('wp_enqueue_scripts', 'outscore_scripts');`);
            message.success('Copied to clipboard!');
          }}
        >
          Copy
        </Button>
        <pre>
          <code>
            {`
  function outscore_scripts() {
    // Register the script
    wp_register_script('outscore-js', 'https://api.outscore.ai/admin/outscorejs', array('jquery'), null, true);
    $client_id = 'YOUR_CLIENT_ID_HERE';
    wp_localize_script(
        'outscore-js', // Handle
        'myScriptData', // Object name
        array(
            'clientId' => $client_id, // Data to pass to the script
        )
    );
  
    // Enqueue the script
    wp_enqueue_script('outscore-js');
  }
  add_action('wp_enqueue_scripts', 'outscore_scripts');
  `}
          </code>
        </pre>
      </div>
    </div>
  );

// Updated OptionCard component
const OptionCard = ({ option }) => {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
    console.log(`${option.title} toggled: ${!isToggled}`);
  };
};

  const [sitemapUrlList, setSitemapUrlList] = useState([]);
  //const [completedSitemapUrls, setCompletedSitemapUrls] = useState([]);
  const [submitTrigger, setSubmitTrigger] = useState(false);
  const [sitemapMsg, setSitemapMsg] = useState('');
  const [sitemapWaiting, setSitemapWaiting] = useState(false);

  const handleSitemapSubmit = async () => {
  
    /* const payload = {
      sitemap_url: sitemapUrl,
      username: username
    };
    console.log('Payload:', payload);
  
    const token = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    try {
      const response = await axios.post(`${api_base_url}/admin/sitemap`, JSON.stringify(payload), {headers});
      console.log(response.data); // Handle success
    } catch (error) {
      console.error(error); // Handle error
    } */
      
    setSubmitTrigger(true);
    
  };    
  useEffect(() => {
    if (!submitTrigger) return;

    const eventSource = new EventSource(`${api_base_url}/admin/sitemap/post?username=${encodeURIComponent(username)}&sitemap_url=${encodeURIComponent(sitemapUrl)}&token=${encodeURIComponent(localStorage.getItem('token'))}`);

    eventSource.onmessage = function(event) {
        try {
            console.log(event.data);
            const parsedData = JSON.parse(event.data);
            if (parsedData.status === "completed") {
                // If the final result is received
                setSitemapUrlList([]);
                setSitemapMsg(parsedData.msg);
                setSitemapWaiting(false);
                fetchSitemaps();
            } else if (parsedData.status === "full_list" || parsedData.status === "completed_items" || parsedData.status === "completed_sitemap") {
                //const updatedList = parsedData['list'].split(',').map(url => ({ url, processed: false }));
                setSitemapUrlList(parsedData.list);
                setSitemapMsg(parsedData.msg);
                setSitemapWaiting(true);
            }
        } catch (error) {
            // Handle progress updates that aren't JSON, if any
            setSitemapUrlList([]);
            setSitemapMsg([`${error}`]);
            setSitemapWaiting(false);
        }
    };

    eventSource.onerror = function() {
        eventSource.close();
    };

    return () => {
        eventSource.close();
    };
  }, [submitTrigger, username, sitemapUrl]);

  const handleFileChange = (info) => {
    console.log(info);
    if (info.file) {
      setSelectedFile(info.fileList[info.fileList.length-1].originFileObj);
      setSelectedFileList([info.file]);
      setErrorMessage('');
    } else {
      setErrorMessage('No file selected');
    }
  };

  const handleFileSubmit = async () => {
  
    if (!selectedFile) {
      setErrorMessage('No file selected');
      return;
    }
  
    // Check if the selected file is a PDF
    if (selectedFile.type !== 'application/pdf') {
      setErrorMessage('Only PDF files are allowed');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile); // Assuming `selectedFile` is the file object
    formData.append('username', username);
  
    const token = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${token}`,
      // 'Content-Type': 'multipart/form-data' is automatically set by the browser when using FormData
    };
  
    try {
      const response = await axios.post(`${api_base_url}/admin/file`, formData, { headers });
      console.log(response.data); // Handle success
      setSelectedFile(null);
      setSelectedFileList([]);
    } catch (error) {
      console.error(error); // Handle error
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="logout" onClick={handleLogout} icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

    /* return (
      <div>
        <header style={stylesheet.header}>
          <div style={stylesheet.headerRight}>
            <div style={stylesheet.dropdown}>
              <button onClick={toggleDropdown} style={stylesheet.dropbtn}>
                <i className="fas fa-user"></i>
              </button>
              {dropdownOpen && (
                <div style={stylesheet.dropdownContent}>
                  <a href="#" onClick={handleLogout} style={stylesheet.dropdownLink}>Logout</a>
                </div>
              )}
            </div>
          </div>
        </header>
        <div style={stylesheet.container}>
          <div style={stylesheet.sidebar}>
            <div style={stylesheet.logo}>
              <img style={stylesheet.imgLogo} src={logo} alt="Logo" />
              <h2>OutScore</h2>
            </div>
            <ul style={stylesheet.menu}>
              <li
                style={{
                  ...stylesheet.menuItem,
                  ...(activeMenu === 'Home' ? stylesheet.menuItemActive : stylesheet.menuItemInactive)
                }}
                onClick={() => handleMenuClick('Home')}
              >
                Home
              </li>
              <li style={stylesheet.menuItem}>
                <p style={{cursor: 'auto'}} >Salesbot Training</p>
                <ul style={stylesheet.subMenu}>
                  {['Input Sources', 'Deploy'].map((item) => (
                    <li
                      key={item}
                      style={{
                        ...stylesheet.subMenuItem,
                        ...(activeMenu === item ? stylesheet.menuItemActive : stylesheet.menuItemInactive)
                      }}
                      onClick={() => handleMenuClick(item)}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </li>
              <li style={stylesheet.menuItem}>
                <p style={{cursor: 'auto'}} >Insights & Action</p>
                <ul style={stylesheet.subMenu}>
                  <li
                    key="User Chat Logs"
                    style={{
                      ...stylesheet.subMenuItem,
                      ...(activeMenu === 'User Chat Logs' ? stylesheet.menuItemActive : stylesheet.menuItemInactive)
                    }}
                    onClick={() => handleMenuClick('User Chat Logs')}
                  >
                    User Chat Logs
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div style={stylesheet.content}>
            {renderRightPanel()}
          </div>
        </div>
      </div>
    ); */
  
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="Logo" style={{ height: '32px', marginRight: '16px' }} />
            <h2 style={{ color: 'white', margin: 0 }}>OutScore</h2>
          </div>
          <Dropdown overlay={menu} trigger={['click']} visible={dropdownOpen} onVisibleChange={toggleDropdown}>
            <Button type="text" icon={<UserOutlined style={{ color: 'white' }}  />} />
          </Dropdown>
        </Header>
        <Layout>
          <Sider width={200} className="site-layout-background">
            <Menu
              mode="inline"
              selectedKeys={[activeMenu]}
              style={{ height: '100%', borderRight: 0 }}
              onClick={({ key }) => handleMenuClick(key)}
            >
              <Menu.Item key="Home">Home</Menu.Item>
              <SubMenu key="SalesbotTraining" title="Salesbot Training">
                <Menu.Item key="Input Sources">Input Sources</Menu.Item>
                {/* <Menu.Item key="Bot Design">Bot Design</Menu.Item>
                <Menu.Item key="Output Format">Output Format</Menu.Item> */}
                <Menu.Item key="Manage Content">Manage Content</Menu.Item>
                <Menu.Item key="Deploy">Deploy</Menu.Item>
                <Menu.Item key="Settings">Settings</Menu.Item>
              </SubMenu>
              <SubMenu key="InsightsAction" title="Insights & Action">
                <Menu.Item key="Visitor Stats">Visitor Stats</Menu.Item>
                <Menu.Item key="Visitor Chat Logs">Visitor Chat Logs</Menu.Item>
                <Menu.Item key="Conversions">Conversions</Menu.Item>
              </SubMenu>
              <SubMenu key="SocialMedia" title="Social Media">
                <Menu.Item key="InputCollection">Input Collection</Menu.Item>
              </SubMenu>
            </Menu>
          </Sider>
          <Layout style={{ padding: '0 10px 10px' }}>
            <Content
              style={{
                padding: 10,
                margin: 0,
                minHeight: 280,
              }}
            >
              {renderRightPanel()}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
};

const stylesheet = {
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px',
    backgroundColor: '#E8F0FE', // Lighter blue
    width: '80%', // Adjust width to not span the full width
    marginLeft: '20%', // Align with the sidebar
    boxSizing: 'border-box', // Ensure padding is included in the width
  },
  headerRight: {
    position: 'relative',
  },
  dropdown: {
    position: 'relative',
    display: 'inline-block',
  },
  dropbtn: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '18px',
    color: '#1A73E8', // Light blue for text
  },
  dropdownContent: {
    display: 'block',
    position: 'absolute',
    right: 0,
    backgroundColor: '#FFFFFF', // White background for dropdown
    minWidth: '160px',
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.1)', // Softer shadow
    zIndex: 1,
  },
  dropdownLink: {
    color: '#333', // Darker gray for links
    padding: '12px 16px',
    textDecoration: 'none',
    display: 'block',
  },
  dropdownLinkHover: {
    backgroundColor: '#E8F0FE', // Light blue on hover
  },  
  container: {
    display: 'flex',
    minHeight: '100vh',
    overflow: 'hidden',
  },
  sidebar: {
    width: '20%',
    background: '#F1F4F9', // Very light gray-blue
    color: '#333', // Darker text
    padding: '20px',
    position: 'fixed', // Make the sidebar fixed
    top: 0,
    bottom: 0,
    boxSizing: 'border-box', // Ensure padding is included in the width
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: '#E8F0FE',
  },
  content: {
    width: '80%',
    padding: '20px',
    background: '#FAFBFC', // Light gray for content
    marginLeft: '20%', // Adjust content to align with the sidebar
    boxSizing: 'border-box', // Ensure padding is included in the width
    overflow: 'hidden',
  },
  logo: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  imgLogo: {
    width: '100px',
  },
  menu: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  menuItem: {
    padding: '12px 15px',
    margin: '8px 0',
    cursor: 'pointer',
    //borderRadius: '5px',
    transition: 'all 0.3s ease',
    //fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: '#E8F0FE', // Light blue background
    color: '#1A73E8', // Blue text
  },
  menuItemInactive: {
    backgroundColor: '#E1E9F0', // Slightly darker light blue
    boxShadow: '0 3px 5px rgba(0,0,0,0.1)',
    transform: 'translateY(-2px)',
    '&:hover': {
      backgroundColor: '#D0E3FA', // Lighter blue on hover
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 6px rgba(0,0,0,0.05)',
    },
  },
  menuItemActive: {
    backgroundColor: '#1A73E8', // Bright blue
    color: '#FFFFFF', // White text
    boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.05)',
    transform: 'translateY(1px)',
  },
  subMenu: {
    listStyleType: 'none',
    paddingLeft: '0px',
  },
  subMenuItem: {
    cursor: 'pointer',
    padding: '5px 0',
  },
  inputContainer: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  inputGroup: {
    marginBottom: '20px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#1A73E8', // Blue text for labels
  },
  input: {
    width: '100%',
    padding: '10px',
    margin: '0 0 10px 0',
    borderRadius: '8px',
    border: '1px solid #B0C4DE', // Light blue-gray border
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    fontSize: '16px',
  },
  submitButton: {
    backgroundColor: '#1A73E8', // Bright blue
    color: 'white', // White text
    padding: '12px 24px', // Padding around the text
    border: 'none', // No border
    borderRadius: '8px', // Rounded corners
    cursor: 'pointer', // Pointer cursor on hover
    fontSize: '16px', // Text size
    margin: '10px 0', // Margin for spacing
    transition: 'background-color 0.3s ease',
  },
  submitButtonHover: {
    backgroundColor: '#0056b3', // Darker blue on hover
  },
  boxMsg: {
    backgroundColor: '#FFFFFF', // White for message box
    border: '1px solid #E1E9F0', // Light blue-gray border
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)', // Softer shadow
    borderRadius: '12px',
    padding: '20px',
    fontFamily: "'Arial', sans-serif",
    textAlign: 'center',
    maxWidth: '500px',
    margin: '20px auto',
    color: '#333', // Darker gray for text
  },
  boxMsgH1: {
    fontSize: '28px',
    color: '#1A73E8', // Blue for headings
    marginBottom: '10px',
  },
  boxMsgP: {
    fontSize: '16px',
    margin: '10px 0',
    color: '#666', // Medium gray for paragraphs
  },
  interactionContainer: {
    padding: '20px',
  },
  optionsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  optionCard: {
    width: '250px', // Increased width to accommodate longer descriptions
    padding: '15px',
    margin: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#FAFBFC', // Light gray for cards
  },
  optionTitle: {
    marginBottom: '10px',
    fontSize: '1.2em',
    color: '#1A73E8', // Blue for option titles
  },
  optionText: {
    marginBottom: '15px',
    fontSize: '0.9em',
    lineHeight: '1.4',
    color: '#333', // Dark gray for text
  },
  placeholderImage: {
    width: '100%',
    height: 'auto',
    marginBottom: '10px',
  },
  switchContainer: {
    position: 'relative',
    display: 'inline-block',
    width: '60px',
    height: '34px',
  },
  switchInput: {
    opacity: 0,
    width: 0,
    height: 0,
  },
  switchSlider: (isToggled) => ({
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: isToggled ? '#1A73E8' : '#ccc', // Blue when toggled
    transition: '.4s',
    borderRadius: '34px',
    '&:before': {
      position: 'absolute',
      content: '""',
      height: '26px',
      width: '26px',
      left: isToggled ? '26px' : '4px',
      bottom: '4px',
      backgroundColor: 'white',
      transition: '.4s',
      borderRadius: '50%',
    },
  }),
  //chat display css
  chatContainer: {
      display: 'flex',
      height: '100vh',
    },
    conversationList: {
      width: '20%',
      borderRight: '1px solid #ccc',
      overflowY: 'auto',
    },
    messageContainer: {
      width: '80%',
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    messageList: {
      flex: 1,
      overflowY: 'auto',
    },
    tabContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      marginBottom: '20px',
      borderBottom: '2px solid #ddd',
    },
    tab: {
      padding: '10px 20px',
      cursor: 'pointer',
      backgroundColor: '#E8F0FE', // Light blue for tabs
      border: '1px solid #ddd',
      borderBottom: 'none',
      borderRadius: '5px 5px 0 0',
      marginBottom: '-2px',
      transition: 'background-color 0.3s ease',
      color: '#1A73E8', // Blue text for tabs
    },
    activeTab: {
      padding: '10px 20px',
      cursor: 'pointer',
      backgroundColor: '#1A73E8', // Bright blue for active tab
      color: '#fff', // White text for active tab
      border: '1px solid #ddd',
      borderBottom: 'none',
      borderRadius: '5px 5px 0 0',
      marginBottom: '-2px',
      transition: 'background-color 0.3s ease',
    },
    contentContainer: {
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '5px',
      backgroundColor: '#FAFBFC', // Light gray for content area
    },    
};

export default AdminHomePage;

//Old code

/* case 'Bot Design':
          return (
            <div style={stylesheet.interactionContainer}>
              <h2>Bot Design</h2>
              <div style={stylesheet.optionsContainer}>
                {optionsData.map((option) => (
                  <OptionCard key={option.id} option={option} />
                ))}
              </div>
            </div>
          );
        case 'Output Format':
          return (
            <div style={stylesheet.interactionContainer}>
              <h2>Output Formats</h2>
              <div style={stylesheet.optionsContainer}>
                {outputFormatData.map((option) => (
                  <OptionCard key={option.id} option={option} />
                ))}
              </div>
            </div>
          ); */  