// InputCollectionWizard.js
import React, { useState, useEffect } from 'react';
import { Steps, Button, message, Form, Input, Select, InputNumber, Progress } from 'antd';
import axios from 'axios';

const { Step } = Steps;
const { Option } = Select;

const InputCollectionWizard = () => {
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [progress, setProgress] = useState(0);
  const api_base_url = 'https://api.outscore.ai';

  const steps = [
    {
      title: 'Basic Info',
      content: (
        <>
          <Form.Item name="companyName" label="Company Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="industry" label="Industry" rules={[{ required: true }]}>
            <Select>
              <Option value="tech">Technology</Option>
              <Option value="finance">Finance</Option>
              <Option value="healthcare">Healthcare</Option>
              {/* Add more options as needed */}
            </Select>
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Products/Services',
      content: (
        <>
          <Form.Item name="productName" label="Product Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="productDescription" label="Product Description" rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Target Audience',
      content: (
        <>
          <Form.Item name="targetAge" label="Target Age Range" rules={[{ required: true }]}>
            <InputNumber min={0} max={100} />
          </Form.Item>
          <Form.Item name="targetLocation" label="Target Location" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </>
      ),
    },
    // Add more steps as needed
  ];

  useEffect(() => {
    // Load saved progress
    const savedProgress = localStorage.getItem('inputWizardProgress');
    if (savedProgress) {
      const { step, formData } = JSON.parse(savedProgress);
      setCurrent(step);
      form.setFieldsValue(formData);
      setProgress((step / (steps.length - 1)) * 100);
    }
  }, []);

  /* const next = () => {
    form.validateFields().then(() => {
      setCurrent(current + 1);
      saveProgress(current + 1);
    });
  }; */
  const next = () => {
    setCurrent(current + 1);
    setProgress(((current + 1) / steps.length) * 100);
  };

  /* const prev = () => {
    setCurrent(current - 1);
    saveProgress(current - 1);
  }; */
  const prev = () => {
    setCurrent(current - 1);
    setProgress(((current - 1) / steps.length) * 100);
  };

  const saveProgress = (step) => {
    const formData = form.getFieldsValue();
    localStorage.setItem('inputWizardProgress', JSON.stringify({ step, formData }));
    setProgress((step / (steps.length - 1)) * 100);
  };

  const onFinish = async (values) => {
    try {
      const token = localStorage.getItem('token');
      let username = localStorage.getItem('username');
      const headers = {
        'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'multipart/form-data' is automatically set by the browser when using FormData
      };
      const dataToSend = {
        company_name: values.companyName,
        industry: values.industry,
        product_name: values.productName,
        product_description: values.productDescription,
        target_age: values.targetAge,
        target_location: values.targetLocation,
        username: username,
      };

      await axios.post(`${api_base_url}/admin/input-collection`, dataToSend, { headers }
      );
      message.success('Input collection completed successfully!');
      localStorage.removeItem('inputWizardProgress');
    } catch (error) {
      message.error('An error occurred while saving the data.');
    }
  };

  return (
    <div>
      <Progress percent={progress} status="active" />
      <Steps current={current}>
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div style={{ marginTop: 24 }}>
      <Form form={form} onFinish={onFinish}>
          {steps.map((item, index) => (
            <div key={index} style={{ display: index === current ? 'block' : 'none' }}>
              {item.content}
            </div>
          ))}
        </Form>
      </div>
      <div style={{ marginTop: 24 }}>
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => form.submit()}>
            Done
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </div>
    </div>
  );
};

export default InputCollectionWizard;